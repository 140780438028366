/**
 * Routes of service
 *
 * All routes must be use layout component on first level (with path "/")
 * Root of service index component must have redirect to service index page (it may any name - not only index)
 */

export default [
    {
        path: "/",
        redirect: { name: "idea.index" },
        name: "root",
        components: {
            default: () => import("@/view/layout/Layout"),
        },
        children: [
            {
                path: "/",
                components: {
                    default: () => import("./layouts/IdeaLayout"),
                    "title-actions": () => import("./components/ActionsButton.vue"),
                    search: () => import("./components/Searchbar"),
                },
                children: [
                    {
                        path: "/",
                        name: "idea.index",
                        component: () => import("./views/IdeaIndex"),
                    },
                    {
                        path: "card/:id",
                        name: "idea.card",
                        component: () => import("./views/IdeaShow")
                    },
                    {
                        path: "edit/:id",
                        name: "idea.edit",
                        component: () => import("./views/IdeaEdit")
                    },
                    {
                        path: "new",
                        name: "idea.new",
                        component: () => import("./views/IdeaEdit")
                    },
                    {
                        path: "categories",
                        name: "category.index",
                        component: () => import("./views/CategoryIndex")
                    },
                    {
                        path: "category/edit/:id",
                        name: "category.edit",
                        component: () => import("./views/CategoryEdit")
                    },
                    {
                        path: "category/new",
                        name: "category.new",
                        component: () => import("./views/CategoryEdit")
                    },
                    {
                        path: "/moderation",
                        name: "moderation",
                        component: () => import("./views/Moderation")
                    },
                    {
                        path: "category/:id",
                        name: "category.show",
                        component: () => import("./views/CategoryShow")
                    }
                ]
            },
            {
                path: "/statistics",
                name: "statistics",
                component: () => import("./views/Statistics")
            },
            {
                path: "/help",
                component: () => import("./views/Help.vue"),
                name: "help"
            },
        ]
    }
];
